<template>
  <div id="login">
    <!-- <v-parallax style="height:100vh;" src="https://images.pexels.com/photos/583846/pexels-photo-583846.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260">
    </v-parallax> -->
    <v-container fluid class="pa-0" style="background: url('/static/login-hero2.jpg'); background-size: 100%; background-repeat: no-repeat; background-position: top;">
      <div class="opacity-mask" style="position: absolute; height: 100%; width: 100%"></div>
        <v-container>
        <v-row justify="center" align="center" no-gutters style="" >
          <v-col cols="12" md="6" lg="8" class="pa-0"  v-if="$vuetify.breakpoint.smAndUp" style="background-color: transparent;">
            <v-card flat tile height="calc(100vh - 322px)" class="d-flex align-center" style="background-color: transparent;">
              
              <!-- gradient="to bottom, rgba(0,0,0,.0), rgba(0,77,64,1)" -->
            <v-sheet rounded style="width: 100%; height: 100%;background-color: transparent; backdrop-filter: blur(0px);" class="mx-auto" >
              <!-- height="calc(100vh - 327px)" -->
              <transition name="fade">
                <v-card style="height: 100%; max-width: 50%;" class="elevation-0 mx-auto rounded-xl d-flex align-center"  v-if="show" color="rgba(255,255,255,.0)" >
                  <v-img src="/static/logo2.png"  @load="show= true" >
                    <!-- <v-btn tile color="orange" class="rounded-lg" depressed dark x-large style="left: 50%; margin-left:-150px; margin-bottom: calc(25% - 26px);" width="250px" @click="scrollMeTo('anchor')">
                      <span class="white--text sub-title">
                        NỘP HỒ SƠ NGAY <v-icon>mdi-chevron-down</v-icon>
                      </span>
                    </v-btn> -->
                  </v-img>
                </v-card>
              </transition>
            </v-sheet>
            </v-card>
          </v-col>
          <v-col class="pb-5" cols="12" md="6" lg="4" style="backdrop-filter: blur(0px);" >
            <v-card :loading="logging" class="mx-auto my-auto rounded-xl px-2 elevation-3" style="padding-bottom: 32px; max-width: 400px; border: 0px; border-bottom: 3px; border-style: solid; border-color: #BDBDBD;" rounded="50%" load>
              <div class="pa-2 text-center">
                <img contain style=""  v-show="$vuetify.breakpoint.xsOnly"
                  width="286"
                  src="/static/logo2.png"
                >
              </div>
              <v-card-title class="text-center mb-6" >
                <v-spacer></v-spacer>
                CHÀO BẠN ĐÃ TRỞ LẠI
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text class="text-center">
                <v-form v-model="loginValid" ref="loginForm" id="loginForm" @submit.prevent="login" class="px-6">
                  <v-text-field v-model="username" label="Tên tài khoản" required outlined  :rules='[v=>!!v||"Nhập tài khoản"]' @change="verifyUser"></v-text-field>

                  <v-text-field v-model="password" label="Password" required 
                                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="passwordShow ? 'text' : 'password'" :rules='[v=>!!v||"Nhập mật khẩu"]'
                                @click:append="passwordShow = !passwordShow" outlined ></v-text-field>
                                
                  <div class="d-flex justify-center">
                    <v-checkbox label="Lưu nhớ mật khẩu" v-model="rememberLogin" class="mt-0" hide-details></v-checkbox>
                  </div>
                  <v-btn  width="90%" type="submit" color="amber darken-3" dark class="font-weight-bold rounded-pill my-4 d-block mx-auto" form="loginForm">Đăng nhập
                    <v-icon right>mdi-login</v-icon>
                  </v-btn>

                  <v-row>
                    <v-col cols="6" class="text-left">
                      <router-link class="teal--text mb-2" text :to="{name: 'ChangePassword'}"  style="width: 100%; display: block;">
                        
                        Đổi mật khẩu</router-link>
                      <router-link class="teal--text mb-2" text :to="{name: 'ForgetPassword'}" style="width: 100%; display: block;">
                        
                        Quên mật khẩu</router-link>
                    </v-col>
                    <v-col cols="6" class="text-center">
                      <p class="">Quét để truy cập nhanh </p>
                      <img src="/static/qr_main.svg" alt="" srcset="" width="72" class="d-block mx-auto">
                    </v-col>
                  </v-row>

                </v-form>
              </v-card-text>
              
            </v-card>
          </v-col>
        </v-row>
        </v-container>
        <!-- #187733 -->
      <v-footer color="teal darken-4" class=" text-center" padless style="">
        <v-container>
          
        <mouse-scroll class="" v-show="isMouseVisible" style="position: absolute; top: -95px; left: 50%; transform: translateX(-18px)"></mouse-scroll>
        <v-card color="transparent" class="d-flex justify-space-around align-end elevation-0" dark style="flex-wrap: wrap;">
          <v-col cols="12" md="4" class="px-0">
              <img src="https://cdn-icons-png.flaticon.com/128/4732/4732739.png" class="rounded-xl elevation-6" width="64px" height="64px" style=" background: white; padding: 8px;">
              <!-- <v-icon size="64px">mdi-phone</v-icon> -->
              <div class="text-h6 green--text text--lighten-4 my-2"> Liên hệ</div>
              <div class=" my-2 font-weight-bold yellow--text text--lighten-2 py-2" style="height:44px"> 0867 742 768 | 0888 786 787</div>
          </v-col>
          <v-col cols="12" md="4" class="px-0">
            <img src="https://cdn-icons-png.flaticon.com/128/4732/4732877.png" class="rounded-xl elevation-6" width="64px" style="background: white; padding: 8px;">
              <div class="text-h6 green--text text--lighten-4 my-2"> E-mail</div>
              <div class=" my-2 font-weight-bold yellow--text text--lighten-2 py-2" style="height:44px"> tuyendung@trungsonpharma.com</div>
          </v-col>
          <v-col cols="12" md="4" class="px-0">
            
            <a @click="getApple" class="mx-2">
                <img src="/static/icons/App_Store.png" style="height:47px; width: 150px;">
              </a>
              
              <a @click="getApple(true)" style=" color: white;  text-decoration:underline;">
                <i><h5>Phiên bản cũ iOs</h5></i>
              </a>
              
              <v-divider class="my-4"></v-divider>
              
              <a href="https://play.google.com/store/apps/details?id=vn.trungsoncare.trung_son_hrm" class="mx-2">
                  <img src="/static/icons/google_play_2.png" style="height: 47px; width: 150px;">
              </a>
              <a href="https://play.google.com/store/apps/details?id=vn.trungsonpharma.chamcong&hl=vi" style=" color: white;">
                <i><h5>Phiên bản cũ Android</h5></i>
              </a>
              
            <div class="text-h6 green--text text--lighten-4 my-2"> Tải về ứng dụng chấm công</div>
            <div class="my-2 font-weight-bold yellow--text text--lighten-2"> PDF hướng dẫn
              <v-btn color="transparent" icon style="width=30px; height=45px" 
                  target="_blank" href="https://tshrmapi.trungsonpharma.com/uploads/static/user_guide.pdf"
                  depressed class="mx-1 d-inline-block" large >
                  <img src="/static/icons/PDF_icon.png" width="30px">
              </v-btn>
            </div>
          </v-col>

          <!-- <v-sheet color="transparent elevation-0 flex-grow-1" outlined tile dark style=" max-width: 33.3%;">
          </v-sheet>

          <v-sheet color="transparent elevation-0 flex-grow-1" outlined tile dark style=" max-width: 33.3%;">
          </v-sheet>
          
          <v-sheet color="transparent elevation-0 flex-grow-1" outlined tile dark style=" max-width: 33.3%;">
          </v-sheet> -->

        </v-card>
        </v-container>
      </v-footer>
      
      <v-card tile flat>
        <v-row style=" border-radius: 4px" class="mb-0">
          <v-col cols="12" md="6" class="mx-auto">
            <div class="my-6 mx-5">
              <p class="">
                <span class="font-weight-light teal--text text--darken-4 display-1">ĐỘI NGŨ</span> 
                <span class="ml-2 font-weight-bold teal--text text--darken-4 display-1"><b>QUẢN LÝ</b></span>
              </p>
              <p style="margin-left:5rem" class=" orange--text display-1 font-italic"><span>HIỂU NGÀNH YÊU NGHỀ</span></p>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="6">
            <v-img src="#" width="" style="margin-bottom:-12px">
              <div style="position:relative; top:0; margin-left:50%;" class="red d-block ">
              </div>
            </v-img>
          </v-col> -->
        </v-row>
      </v-card>
      
      <v-dialog v-model="loginDialog" max-width="400px">
        <v-card :loading="logging" class="mx-auto" style="border-bottom: 3px; border-bottom-style: solid; border-bottom-color: #FFBE5F;">
          <v-card-title class="text-center">
            Chào bạn trở lại
          </v-card-title>
          <v-card-text>
            <v-form v-model="loginValid" ref="loginForm" id="loginForm" @submit.prevent="login">
              <v-text-field v-model="username" label="Tên tài khoản" required solo rounded :rules='[v=>!!v||"Nhập tài khoản"]'></v-text-field>

              <v-text-field v-model="password" label="Password" required 
                            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="passwordShow ? 'text' : 'password'" :rules='[v=>!!v||"Nhập mật khẩu"]'
                            @click:append="passwordShow = !passwordShow" solo rounded></v-text-field>
            </v-form>
            <v-checkbox label="Lưu nhớ mật khẩu" v-model="rememberLogin" class="mt-0"></v-checkbox>
            <router-link class="teal--text" text :to="{name: 'ChangePassword'}">
              <v-icon left color="orange darken-3">mdi-lock-reset</v-icon>
              Đổi mật khẩu</router-link>
          </v-card-text>
          <v-card-actions>
            <v-btn width="100%" type="submit" color="amber darken-3" dark class="font-weight-bold" form="loginForm">Đăng nhập</v-btn>
          </v-card-actions>
          
        </v-card>
      </v-dialog>
      <!-- <v-fab-transition v-show="false">
        <v-btn disabled text 
          fixed color="transparent" elevation="0"
          large dark bottom
          style="left: 50%;transform: translateX(-59px); bottom: 150px; width:100px;z-index:99" class="pa-0" rounded 
        >
        </v-btn>
      </v-fab-transition> -->
      <!-- <v-fab-transition>
        <v-btn
          color="white"
          x-large
          bottom right
          fixed 
          @click="loginDialog=!loginDialog"
          class="rounded-pill"
          style="margin-bottom: 40px"
        >
        
          <v-tooltip bottom height="32px">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" left v-on="on">mdi-account</v-icon>
            </template>
            <span>Đăng nhập </span>
          </v-tooltip> Đăng nhập
        </v-btn>
      </v-fab-transition> -->
      
    <recruit-section></recruit-section>
    <!-- <recruit-section-alt></recruit-section-alt> -->
    <!-- <v-sheet light class="">
      <v-container class="py-4">
        <v-card outlined class="px-0" style="background-attachment: fixed; background-color: rgba(0,0,0,.3); background-blend-mode: multiply; background-image: url('/static/smooth-green-background.jpg'); background-size: cover; background-position: left center; background-repeat: no-repeat">
          <v-row class="mx-0 py-6" style="">
            <v-col cols="12" lg="6" class="text-center d-flex align-center pt-6">
              <v-spacer></v-spacer>
              <div class="d-block" style="widows: 100%;">
                <p class="text-h6 font-weight-bold white--text">LIÊN HỆ NGAY ĐỂ ĐƯỢC TƯ VẤN VỀ VIỆC LÀM</p>
                <a target="_blank" class="mx-2" href="https://www.facebook.com/nhathuoctrungson/">
                  <img src="https://cdn-icons-png.flaticon.com/128/3670/3670032.png" width="64px">
                </a>
                <a target="_blank" class="mx-2" href="https://trungsoncare.com/">
                  <img src="https://cdn-icons-png.flaticon.com/128/724/724664.png" width="64px">
                </a>

                <a target="_blank" class="mx-2" href="https://www.youtube.com/channel/UCEGFRl4OC-hf9ZWMXBsk4Kg"><img src="https://cdn-icons-png.flaticon.com/512/3670/3670163.png"  class="d-inline-block" width="64px"></a>
              </div>
              <v-spacer></v-spacer>
              
            </v-col>
            <v-col cols="12" lg="6" class="py-6">
              <v-card class="rounded-xl elevation-5" style="">
                <v-card-title class="text-center" style="word-break:break-word;">
                  <v-spacer></v-spacer>
                  NỘP HỒ SƠ CỦA BẠN
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-stepper v-model="step" class="elevation-0" alt-labels id="stepper">
                  <v-stepper-header class="elevation-0 text-center" style="flex-wrap: nowrap;">
                    <v-stepper-step step="1" color="orange accent-4" :complete="stepValid[0]" complete-icon="mdi-check">
                      Vị trí ứng tuyển
                    </v-stepper-step>

                    <v-divider></v-divider>
                    <v-stepper-step step="2" color="orange accent-4" :complete="stepValid[1]" complete-icon="mdi-check">
                      Thông tin cơ bản
                    </v-stepper-step>

                    <v-divider></v-divider>
                    <v-stepper-step step="3" color="orange accent-4" :complete="stepValid[2]" complete-icon="mdi-check">
                      Thông tin chi tiết
                    </v-stepper-step>
                    
                    <v-divider></v-divider>
                    <v-stepper-step step="4" color="orange accent-4" :complete="stepValid[3]" complete-icon="mdi-check">
                      Học vấn
                    </v-stepper-step>
                    
                    <v-divider></v-divider>
                    <v-stepper-step step="5" color="orange accent-4" :complete="stepValid[4]" complete-icon="mdi-check">
                      Đính kèm
                    </v-stepper-step>
                    
                  </v-stepper-header>
                  <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-card flat>
                          <v-form ref="recruit_step_1" id="recruit_step_1" @submit.prevent="validStep1">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Vị trí ứng tuyển <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.jobCode" hide-details="auto"
                                :items="jobs" item-value="code" item-text="name" :rules="[v => !!v || 'Chọn công việc ứng tuyển']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Kinh nghiệm làm việc <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-select outlined  hide-details="auto"  v-model="form.yearExperience" placeholder="Kinh nghiệm làm việc" clearable
                                :items="yearExperiences"></v-select>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi làm việc mong muốn 1 <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.city1Code" clearable hide-details="auto"
                                :items="cities" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi làm việc mong muốn 2 <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.city2Code" clearable hide-details="auto"
                                :items="cities" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Bạn biết đến trung sơn <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.recruitChannel" clearable hide-details="auto"
                                :items="recruitChannels" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn type="submit" form="recruit_step_1" color="green darken-4" style="width: 180px;" dark >
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="2">
                        <v-card flat>
                          <v-form ref="recruit_step_2" id="recruit_step_2" @submit.prevent="validStep2">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Họ & tên  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field v-model="form.name" outlined hide-details="auto" :rules="[
                                                                        v => !!v || 'Nhập vào họ tên',
                                                                        v => (v || '').length <=30 || 'Tên quá dài']"></v-text-field>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Giới tính  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-select v-model="form.gender" outlined hide-details="auto" :items='[{text: "Nam", value: true}, {text:"Nữ", value:false}]' :rules="[v=> v!=null ||'Chọn 1 dữ liệu' ]"></v-select>
                              </v-col>
                              
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ngày sinh  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-card class="d-inline-flex" outlined>
                                  <v-date-picker no-title class="mx-auto" locale="vi" full-width color="green darken-2"
                                        v-model="form.birthday"
                                    ></v-date-picker>
                                </v-card>
                                <v-text-field hide-details="auto" maxlength="10" v-model="formatedBirthDay" outlined :rules="[
                                                                                                        rules.required,
                                                                                                        rules.validDate,
                                                                                                        rules.minDate
                                                                                                        ]" 
                                              hint="Định dạng ngày dd/mm/yyyy"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Số điện thoại  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field v-model="form.phone" hide-details="auto" placeholder="ví dụ 0972xxxxxx" outlined :rules="[
                                                                          v => !!v || 'Nhập SDT', v => (v || '').length <=15 || 'SDT quá dài',
                                                                          v => (v || '').length >=10 || 'SDT quá ngắn',
                                                                          v =>  !/\D/.test(v) || 'SDT không đúng']"></v-text-field>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Email <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined type="email" placeholder="ví dụ: email@gmail.com" v-model="form.mail" hide-details="auto" :rules="[
                                                                                          v => !!v || 'Nhập Email', v => (v || '').length <=50 || 'Email quá dài',
                                                                                          rules.validEmail]"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn type="submit" form="recruit_step_2" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="3" >
                        <v-card flat>
                          <v-form ref="recruit_step_3" id="recruit_step_3" @submit.prevent="validStep3">
                            <v-row style="">                                                                                          
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Chiều cao <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                  <v-text-field outlined hide-details="auto" v-model="form.height" persistent-placeholder hint="ví dụ 1.8" persistent-hint
                                                placeholder="ví dụ 1.8" suffix="(m)" :rules="[ 
                                                                                                v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                v=> !!v || 'Nhập dữ liệu này'
                                                                                              ]"></v-text-field>
                              </v-col>
                                                                                                                        
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Cân nặng <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined hide-details="auto" v-model="form.weight" label="Cân nặng"  hint="ví dụ 50" persistent-hint
                                                suffix="(kg)" persistent-placeholder placeholder=" ví dụ: 80" :rules="[ 
                                                                                                  v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                  v=> !!v || 'Nhập dữ liệu này'
                                                                                                ]"></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Quốc tịch <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.originCode" placeholder="Việt Nam, ..." persistent-placeholder hide-details="auto"
                                :items="origins" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Dân tộc <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.ethnicityCode" placeholder="Dân tộc Kinh, Hoa, ..." persistent-placeholder hide-details="auto"
                                :items="ethnicities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Tôn giáo <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.religionCode" placeholder="Phật giáo, Công giáo,..." persistent-placeholder hide-details="auto"
                                :items="religions" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                CCCD  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-text-field outlined v-model="form.idNumber" hide-details="auto" :rules="[
                                                                                          v => !!v || 'Nhập chứng minh', v => (v || '').length <=12 || 'CMND quá dài',
                                                                                          v => (v || '').length >=9 || 'CMND quá ngắn',
                                                                                          v =>  !/\D/.test(v) || 'CMND không đúng']"></v-text-field>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Nơi cấp  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined v-model="form.idPlace" :items="idPlaces" hide-details="auto"
                                :rules="[v=>!!v || 'Nhập dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ngày cấp  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-card class="d-inline-flex" outlined>
                                  <v-date-picker no-title class="mx-auto" locale="vi" full-width color="green darken-2" 
                                        v-model="form.idDate"
                                    ></v-date-picker>
                                </v-card>
                                <v-text-field maxlength="10" v-model="formatedIdDate" outlined :rules="[
                                                                                                        rules.required,
                                                                                                        rules.validDate
                                                                                                        ]" 
                                              hint="Định dạng ngày dd/mm/yyyy" hide-details="auto"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Địa chỉ thường trú <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col class="">
                                <v-autocomplete outlined v-model="form.cityCode" placeholder="Thành phố" persistent-placeholder
                                  @change="fetchDistrict(form.cityCode)"
                                  :items="cities" item-value="code" item-text="name" :rules="[
                                                                                        v => !!v || 'Chọn thành phố của bạn']">
                                </v-autocomplete>
                                
                                <v-autocomplete outlined v-model="form.districtCode" placeholder="Quận" persistent-placeholder @change="fetchWard(form.districtCode)"
                                :items="districts" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
                                
                                <v-autocomplete outlined v-model="form.wardCode" placeholder="Phường" persistent-placeholder :rules="[ v => !!v || 'Chọn phường của bạn']"
                                  :items="wards" item-value="code" item-text="name"></v-autocomplete>
                                  
                                <v-textarea outlined rows=3 v-model="form.address" placeholder="ví dụ Thành phố Cần Thơ, quận Bình Thủy, phường 5, 2xx/5 hẻm 5, đường CMT8" persistent-placeholder :rules="[v=>!!v || 'Nhập dữ liệu']" hide-details="auto"></v-textarea>
                              </v-col>

                              <v-col cols="12">
                                <v-divider></v-divider>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Địa chỉ tạm trú <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                  <v-autocomplete outlined v-model="form.dCityCode" placeholder="Thành phố" persistent-placeholder
                                    @change="fetchDistrict2(form.dCityCode)"
                                    :items="cities" item-value="code" item-text="name" :rules="[
                                                                                          v => !!v || 'Chọn thành phố của bạn']">
                                  </v-autocomplete>
                                
                                  <v-autocomplete outlined v-model="form.dDistrictCode" placeholder="Quận" persistent-placeholder @change="fetchWard2(form.dDistrictCode)"
                                  :items="district2s" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
                                
                                  <v-autocomplete outlined v-model="form.dWardCode" placeholder="Phường" persistent-placeholder :rules="[ v => !!v || 'Chọn phường của bạn']"
                                    :items="ward2s" item-value="code" item-text="name"></v-autocomplete>

                                  <v-textarea outlined v-model="form.dAddress" placeholder="Địa chỉ" :rules="[v=>!!v || 'Nhập dữ liệu']"></v-textarea>
                              </v-col>
                              
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn form="recruit_step_3" type="submit" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="4">
                        <v-card flat>
                          <v-form ref="recruit_step_4" id="recruit_step_4" @submit.prevent="validStep4">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Học vấn  <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.educationLevelCode" placeholder="Học vấn" persistent-placeholder
                                :items="educationLevels" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Chuyên ngành <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.educationMajorCode" placeholder="Chuyên ngành"
                                :items="educationMajors" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Cơ sở đào tạo <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>
                                <v-autocomplete outlined hide-details="auto" v-model="form.universityCode" placeholder="Cơ sở đào tạo"
                                :items="universities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                Xếp loại tốt nghiệp <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>       
                                <v-autocomplete outlined hide-details="auto" v-model="form.graduateTypeCode" placeholder="Xếp loại tốt nghiệp" persistent-placeholder
                                :items="[
                                          {text: 'Giỏi', value: 'GIOI'},
                                          {text: 'Khá', value: 'KHA'},
                                          {text: 'Trung Bình', value: 'TRUNGBINH'},
                                          {text: 'Khác', value: 'KHAC'},
                                          {text: 'Chưa tốt nghiệp', value: 'NONE'},
                                        ]" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Loại chứng chỉ hành nghề Dược <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col>       
                                <v-select outlined v-model="form.pharmaCert" :items="pharmaCerts"  persistent-placeholder
                                          placeholder="Loại chứng chỉ hành nghề Dược" :rules="[ v => !!v || 'Chọn dữ liệu']" clearable hide-details="auto">
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn color="red darken-4" style="width: 90px;" dark class="" @click="step--" outlined>
                                Quay lại
                              </v-btn>
                              <v-btn type="submit" form="recruit_step_4" color="green darken-4" dark class="mx-4" style="width: 90px">
                              Tiếp tục</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                      
                      <v-stepper-content step="5">
                        <v-card flat>
                          <v-form ref="recruit_step_5" id="recruit_step_5" @submit.prevent="validStep5">
                            <v-row>
                              <v-col cols="12" md="6" class="d-flex align-center">
                                Ảnh chân dung của bạn <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                
                                <v-img :src="avatarPreview" class="rounded-xl mb-2 mx-auto" width="150px" height="150px">
                                </v-img>
                                <v-file-input show-size outlined
                                label="Avatar" ref="avatarInput" v-model="avatarFile" single-line  prepend-icon="" hide-details="auto"
                                @change="uploadAvatar" :rules="[v=>!!v||'Hãy chọn ảnh chân dung của bạn']">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                CMND (CCCD) mặt trước <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="idImageFrontFile" clearable outlined :rules="[v=>!!v||'Hãy chọn ảnh CMND/CCCD mặt trước của bạn']" placeholder="Ảnh chụp CMND/CCCD mặt trước" persistent-placeholder hide-details="auto" 
                                single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                CMND (CCCD) mặt sau <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="idImageBackFile" clearable outlined :rules="[v=>!!v||'Hãy chọn ảnh CMND/CCCD mặt sau của bạn']" placeholder="Ảnh chụp CMND/CCCD mặt sau" persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>

                              <v-col cols="12" md="6" class="d-flex align-center">
                                File CV <small class="ml-2 orange--text text--darken-2"> (ứng viên viên nên gởi CV)</small> <span class="red--text ml-2" ></span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input v-model="cvFile" accept=".pdf" clearable outlined :rules="[v=>!!v||'Hãy chọn file CV của bạn']" placeholder="File  CV định dạng PDF" persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              
                              <v-col cols="12" md="6" class="d-flex align-center">
                                File đính kèm khác <span class="red--text ml-2">*</span>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-file-input clearable outlined v-model="attachmentFile" multiple accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpg" placeholder="Giấy khám sức khỏe, CV, v.v.v." persistent-placeholder hide-details="auto" single-line  prepend-icon="">
                                  <template v-slot:selection="{ index, text, file }">
                                    <v-chip color="green accent-4" dark label >
                                      {{ text }}  
                                    </v-chip>
                                    </template>
                                </v-file-input>
                              </v-col>
                              <v-col cols="12">
                                <v-textarea v-model="form.note" rows="3" hide-details counter label="Lời nhắn">
                                </v-textarea>
                              </v-col>

                            </v-row>
                          </v-form>
                          <v-card-actions class="mt-6">
                            <v-spacer></v-spacer>
                              <v-btn style="width: 90px;" dark color="red darken-4" @click="step--" outlined>Quay lại</v-btn>
                              <v-btn type="submit" form="recruit_step_5" style="width: 90px;" dark color="green darken-4" :loading="isLoading">GỞI HỒ SƠ</v-btn>
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
              </v-card>
            </v-col>

          </v-row>
        </v-card>
      </v-container>
    </v-sheet> -->

      <!-- <v-sheet class="d-none" light style="background: url(https://images.unsplash.com/photo-1559239115-ce3eb7cb87ea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1988&q=80), #E6E8EA; background-repeat: no-repeat; background-size: 100%;">
        <v-container class="custom text-center pt-12" >
          <v-card class="rounded-xl">
            <v-card-text>
          <div class="  my-5 display-1 black--text" id="tuyendung">Nộp hồ sơ của bạn</div>
          <span ref="anchor"></span>
          <v-form id="candidate" ref="candidate" @submit.prevent="applyJob" v-model="valid">
            <v-row class="mt-0">
              <v-col md="6">
                <v-autocomplete filled v-model="form.jobCode" label="Vị trí ứng tuyển *"
                :items="jobs" item-value="code" item-text="name" :rules="[v => !!v || 'Chọn công việc ứng tuyển']"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field filled v-model="form.name" label="Họ tên *" :rules="[
                                                                        v => !!v || 'Nhập vào họ tên',
                                                                        v => (v || '').length <=30 || 'Tên quá dài']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select filled v-model="form.gender" label="Giới tính *" :items='[{text: "Nam", value: true}, {text:"Nữ", value:false}]' :rules="[v=> v!=null ||'Chọn 1 dữ liệu' ]"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker2 filled v-model="form.birthday" label="Ngày sinh *" :rules="[
                                                                                        rules.required,
                                                                                        rules.validDate,
                                                                                        rules.minDate
                                                                                        ]" 
                  :range="false" :width="600" :readonlyInput="false" :birthDay="false" ></date-picker2>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field filled v-model="form.phone" label="Số điện thoại *" :rules="[
                                                                          v => !!v || 'Nhập SDT', v => (v || '').length <=15 || 'SDT quá dài',
                                                                          v => (v || '').length >=10 || 'SDT quá ngắn',
                                                                          v =>  !/\D/.test(v) || 'SDT không đúng']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field filled type="email" v-model="form.mail" label="Email *" :rules="[
                                                                          v => !!v || 'Nhập Email', v => (v || '').length <=50 || 'Email quá dài',
                                                                          rules.validEmail]"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field filled v-model="form.idNumber" label="Số CMND *" counter :rules="[
                                                                          v => !!v || 'Nhập chứng minh', v => (v || '').length <=12 || 'CMND quá dài',
                                                                          v => (v || '').length >=9 || 'CMND quá ngắn',
                                                                          v =>  !/\D/.test(v) || 'CMND không đúng']"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker2 filled :range="false" :width="600" v-model="form.idDate" label="Ngày cấp *" 
                :rules="[
                          rules.required,
                          rules.validDate
                        ]"  :readonlyInput="false" :birthDay="false"></date-picker2>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete filled v-model="form.idPlace" :items="idPlaces" label="Nơi cấp *" :rules="[v=>!!v || 'Nhập dữ liệu']"></v-autocomplete>
              </v-col>
              
              <label class="subtitle-2 px-3 d-block col-12  text-left">Địa chỉ thường trú</label>
              <v-col cols="12" md="6">
                <v-autocomplete filled v-model="form.cityCode" label="Thành phố *"
                  @change="fetchDistrict(form.cityCode)"
                  :items="cities" item-value="code" item-text="name" :rules="[
                                                                        v => !!v || 'Chọn thành phố của bạn']">
                </v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.districtCode" label="Quận *" @change="fetchWard(form.districtCode)"
                :items="districts" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.wardCode" label="Phường *" :rules="[ v => !!v || 'Chọn phường của bạn']"
                  :items="wards" item-value="code" item-text="name"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-text-field filled v-model="form.address" label="Địa chỉ *" :rules="[v=>!!v || 'Nhập dữ liệu']"></v-text-field>
              </v-col>

              <label class="subtitle-2 px-3 d-block col-12  text-left">Địa chỉ tạm trú</label>
              <v-col cols="12" md="6">
                <v-autocomplete filled v-model="form.dCityCode" label="Thành phố *"
                  @change="fetchDistrict2(form.dCityCode)"
                  :items="cities" item-value="code" item-text="name" :rules="[
                                                                        v => !!v || 'Chọn thành phố của bạn']">
                </v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.dDistrictCode" label="Quận *" @change="fetchWard2(form.dDistrictCode)"
                :items="district2s" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn quận của bạn']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.dWardCode" label="Phường *" :rules="[ v => !!v || 'Chọn phường của bạn']"
                  :items="ward2s" item-value="code" item-text="name"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-text-field filled v-model="form.dAddress" label="Địa chỉ *" :rules="[v=>!!v || 'Nhập dữ liệu']"></v-text-field>
              </v-col>
              
              <label class="subtitle-2 px-3 d-block col-12  text-left">Lý lịch</label>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.ethnicityCode" label="Dân tộc *"
                :items="ethnicities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.religionCode" label="Tôn giáo *"
                :items="religions" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.originCode" label="Quốc tịch *"
                :items="origins" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>

              <label class="subtitle-2 px-3 d-block col-12  text-left">Học vấn</label>
              <v-col  cols="12" md="6" >
                <v-autocomplete filled v-model="form.educationLevelCode" label="Học vấn *"
                :items="educationLevels" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.educationMajorCode" label="Chuyên ngành *"
                :items="educationMajors" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" >
                <v-autocomplete filled v-model="form.universityCode" label="Trường *"
                :items="universities" item-value="code" item-text="name" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" >
                <v-autocomplete filled v-model="form.graduateTypeCode" label="Xếp loại tốt nghiệp *"
                :items="[
                          {text: 'Giỏi', value: 'GIOI'},
                          {text: 'Khá', value: 'KHA'},
                          {text: 'Trung Bình', value: 'TRUNGBINH'},
                          {text: 'Khác', value: 'KHAC'},
                        ]" :rules="[ v => !!v || 'Chọn dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" >
                <v-select v-model="form.pharmaCert" :items="pharmaCerts" label="Loại chứng chỉ hành nghề Dược" :rules="[ v => !!v || 'Chọn dữ liệu']" clearable hide-details="auto" filled>
                </v-select>
              </v-col>
              <label class="subtitle-2 px-3 d-block col-12 text-left">Ngoại hình</label>
              <v-col cols="6">
                <v-text-field filled v-model="form.height" label="Chiều cao" placeholder="ví dụ 1.8" suffix="(m)" :rules="[ 
                                                                                                                            v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                                            v=> !!v || 'Nhập dữ liệu này'
                                                                                                                          ]"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field filled v-model="form.weight" label="Cân nặng" suffix="(kg)" placeholder=" ví dụ: 80" :rules="[ 
                                                                                                                            v=> !isNaN(v) || 'Nhập dữ liệu số',
                                                                                                                            v=> !!v || 'Nhập dữ liệu này'
                                                                                                                          ]"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label :class="`subtitle-2 d-block text-left ${avatarError? 'error--text':''}`">Nhập avatar của bạn (*)</label>
                <v-hover v-slot="{ hover }">
                    <v-avatar
                        color="grey" size="128" >
                        <v-img :src="avatarPreview">
                            <v-expand-transition>
                                <div v-if="!avatarFile" class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal white--text"
                                style="height: 100%;" @click="chooseAvatar">
                                    <v-icon large style="cursor:pointer">mdi-camera</v-icon>Avatar
                                </div>
                            </v-expand-transition>
                        </v-img>
                    </v-avatar>
                </v-hover>
              </v-col>
              
              <v-file-input show-size filled class="d-none"
              label="Avatar" ref="avatarInput" v-model="avatarFile"
              prepend-icon="mdi-camera" @change="uploadAvatar" :rules="[v=>!!v||'Up ảnh avatar của bạn']">
              </v-file-input>
              <v-col cols="12" md="6">
                <v-file-input filled v-model="attachmentFile" label="File đính kèm" multiple accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpg" outlined dense prepend-icon=""
                  append-icon="mdi-file"
                  placeholder=""
                  persistent-hint hint=""
                ></v-file-input>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.recruitChannel" label="Bạn biết đến Trung Sơn" outlined clearable dense
                :items="recruitChannels" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-select filled v-model="form.yearExperience" label="Kinh nghiệm làm việc" outlined clearable dense
                :items="yearExperiences"></v-select>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.city1Code" label="Nơi làm việc mong muốn 1" outlined clearable dense
                :items="cities" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col  cols="12" md="6">
                <v-autocomplete filled v-model="form.city2Code" label="Nơi làm việc mong muốn 2" outlined clearable dense
                :items="cities" item-text="name" item-value="code" :rules="[ v => !!v || 'Chọn một dữ liệu']"></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea filled outlined v-model="form.note" label="Lời nhắn" :rules="[ v => (v || '').length <=800 || 'Dữ liệu quá dài vui lòng không nhập quá 800 kí tự'
                                                                                  ]"></v-textarea>
              </v-col>
            </v-row>
            <v-btn color="orange" dark type="submit" form="candidate" :disabled="isLoading">
              Gởi hồ sơ
            </v-btn>
          </v-form>
            </v-card-text>
          </v-card>
        </v-container>
      </v-sheet> -->
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import http from "../../components/services/http-common";
import ApiService from "../../components/services/ApiServices";
import EventBus  from "../../components/services/EventBus";
import router from "../../router/index";
import moment from 'moment';
import Scroll from '@/components/Icon/MouseScroll';
import Swal from 'sweetalert2';
import Api from './api.js';
import * as easings from 'vuetify/lib/services/goto/easing-patterns'
import RecruitSection from "@/components/RecruitSection"
import RecruitSectionAlt from "@/components/RecruitSectionAlt"

const dateRegex = new RegExp('[0-9]{1,2}(/|-)[0-9]{1,2}(/|-)[0-9]{4}');

var minBirthDay = moment().startOf("year").subtract(19, "years").format("YYYY-MM-DD");

export default {
  name: 'Login',
  watch:{
    // "form.attachmentFile":{
    //   handler(val) {
    //     console.log(val.size)
    //   }
    // }
  },
  components: {
    "mouse-scroll":Scroll,
    "recruit-section": RecruitSection,
    RecruitSectionAlt
  },
  props: {
      data: {
        type: String,
        default: "ssss"
      },
  },
  data() {
    return {
      isScrolling: true,
      isMouseVisible: true,
      stepValid: [false, false, false, false, false],
      step: 1,
      show:false,
      username: "",
      password: "",
      passwordShow: false,
      logging: false,
      loginValid: true,
      test: "",
      loginDialog: false,
      rememberLogin: false,

      CANDIDATE_URL: "candidates",
      jobs:[],
      cities:[],
      districts:[],
      wards:[],
      district2s:[],
      ward2s:[],

      ethnicities:[],
      origins:[],
      religions:[],

      educationLevels:[],
      educationMajors:[],
      universities:[],
      yearExperiences:[
        {text: " Chưa có kinh nghiệm", value: 0},
        {text: " 6 tháng", value: 0.5},
        {text: " 1 năm", value: 1},
        {text: " 2 năm", value: 2},
        {text: " 3 năm", value: 3},
        {text: " Hơn 3 năm", value: 4},
      ],
      valid: true,
      avatarPreview: "/sample.png",
      base64_cccd_front: "",
      attachmentFile:undefined,
      avatarFile:undefined,
      idImageFrontFile:undefined,
      idImageBackFile:undefined,
      cvFile: undefined,
      isLoading:false,
      recruitChannels:[
        {text: "Mạng xã hội", value: "MXH"},
        {text: "Tờ rơi", value: "TR"},
        {text: "Được giới thiệu", value: "N"},
        {text: "Trang tuyển dụng", value: "TTD"},
      ],
      pharmaCerts:[
        {text: "Không có", value: "KHONG"},
        {text: "CCHN Cao Đẳng", value: "CD"},
        {text: "CCHN Đại Học", value: "DH"},
      ],
      form: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",

        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
      },
      defaultForm: {
        jobCode:null,
        name:"",
        gender: null,
        birthday:moment().format("YYYY-MM-DD"),
        phone:"",
        mail:"",
        
        cityCode:null,
        districtCode:null,
        wardCode:null,
        address:"",

        city1Code:"",
        city2Code:"",

        dCityCode:null,
        dDistrictCode:null,
        dWardCode:null,
        dAddress:"",
        
        ethnicityCode: "",
        originCode: "",
        religionCode: "",

        educationLevelCode:null,
        educationMajorCode:null,
        universityCode:null,
        yearExperience:0,
        weight:0,
        height:0,
        idNumber:"",
        idDate:moment().format("YYYY-MM-DD"),
        idPlace: "",
        note:"",
        recruitChannel: "TTD",
        graduateTypeCode:null,
        pharmaCert: null,
      },
      scrollTop:0,
      rules: {
        required: v=>!!v || 'Nhập dữ liệu',
        validEmail: v =>  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email không đúng',
        validDate: v=> dateRegex.test(v) || 'Dữ liệu không đúng, định dạng ngày dd/mm/yyyy',
        minDate: v=> !moment(v, "DD/MM/YYYY").isAfter(minBirthDay) || 'Bạn cần đủ tuổi để nộp hồ sơ'
      },
      isUserPending: false,
    }
  },
  computed: {
    scrollOptions() {
      return {
          duration: 500,
          offset: 0,
          easing: easings['easeInOutQuad']
        }
    },
    idPlaces() {
        let fixedData = [ "Cục Cảnh sát ĐKQL cư trú và DLQG về dân cư", "Cục Cảnh sát quản lý hành chính về trật tự xã hội"];
        let cityData = this.cities.map(x=>{return x.name});
        return fixedData.concat(cityData);
    },
    avatarError: function(){
      if(this.avatarFile===null)
        return true;
      console.log(this.avatarFile)
      return false;
    },
    height () {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    
    formatedBirthDay: {
      get() {
        return moment(this.form.birthday).format("DD/MM/YYYY")
      },
      set(value) {
        const possibleFormats = ["D/M/YYYY", "DD/MM/YYYY"]
          let parsedDate = moment(value,possibleFormats, true);
          if (parsedDate.isValid()) {
            this.form.birthday = parsedDate.format("YYYY-MM-DD")
          }
      }
    },

    formatedIdDate: {
      get() {
        return moment(this.form.idDate).format("DD/MM/YYYY")
      },
      set(value) {
        const possibleFormats = ["D/M/YYYY", "DD/MM/YYYY"]
          let parsedDate = moment(value,possibleFormats, true);
          if (parsedDate.isValid()) {
            this.form.idDate = parsedDate.format("YYYY-MM-DD")
          }
      }
    }
    // avatar(){
    //     if(!!this.form.avatar)
    //         return process.env.VUE_APP_BASE_URL + this.form.avatar.replace('wwwroot/', '');
    //     else
    //         return '/sample.png';
    // },
  },
  methods: {
    //validation
    validStep1() {
      this.stepValid[0] = this.$refs.recruit_step_1.validate()
      if(this.stepValid[0]){
        this.nextStep()
      }
    },
    validStep2() {
      this.stepValid[1] = this.$refs.recruit_step_2.validate()
      if(this.stepValid[1]){
        this.nextStep()
      }
    },
    validStep3() {
      this.stepValid[2] = this.$refs.recruit_step_3.validate()
      if(this.stepValid[2]){
        this.nextStep()
      }
    },
    validStep4() {
      this.stepValid[3] = this.$refs.recruit_step_4.validate()
      if(this.stepValid[3]){
        this.nextStep()
      }
    },
    validStep5() {
      this.stepValid[4] = this.$refs.recruit_step_5.validate()
      if(this.stepValid[4]){
        this.applyJob()
      }
      
    },

    // utils
    createBase64Image(FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.base64_cccd_front = event.target.result;
      }
      reader.readAsDataURL(FileObject);
    },
    
    nextStep() {
      this.step ++
      this.$vuetify.goTo('#stepper', this.scrollOptions)
    },

    getApple(isLegacy = false)
    {
        return http.get("employees/apple-download", {
          params: {
            legacy: isLegacy
          }
        }).then(res => {
            // window.location.replace(res.data.url);
            // let a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style = "display: none";
            // a.href = res.data.url;
            // a.click();
            // document.body.removeChild(a);
            window.location.href =res.data.url
        })
    },
    uploadAvatar(file) {  
        if (file) {
          this.avatarPreview = URL.createObjectURL(file);
        }
    },
    chooseAvatar(){
      this.$refs.avatarInput.$refs.input.click()
    },
    loadImg() {
    },
    verifyUser() {
      let username = this.username
      return this.apiService.verifyUser(username).then(d => {
        if(d.key == 'PENDING'){
          this.isUserPending = true
        } else {
          this.isUserPending = false
        }
      })
    },
    login () {
      var vm = this;
      this.logging = "green";
      this.$refs.loginForm.validate();
      if(this.loginValid)
      {
        return http.post('account',{
          UserName: this.username,
          Password: this.password
        })
        .then(response =>  {
          // this.logging = false;
          vm.test = response;
          var strTOKEN = JSON.stringify(response.data.user);
          localStorage.setItem('user', strTOKEN);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userClaim', JSON.parse(strTOKEN).claims );
          localStorage.setItem('validTo', response.data.validTo);
          localStorage.setItem('WebAllowed', response.data.webAllowed);

          if(this.rememberLogin){
            localStorage.setItem('rememberUser', this.username);
            localStorage.setItem('rememberPassword', this.password);
          }else {
            localStorage.removeItem('rememberUser')
            localStorage.removeItem('rememberPassword')
          }

          let redirect = vm.$route.query.redirect
          let route = vm.$route.query.route
          let id = vm.$route.query.id
          let id2 = vm.$route.query.id2

          console.log("DEBUG:",redirect==true)
          if(redirect ==true){
            router.push({ name: route, params:{
              id: id,
              id2: id2,
            }})
          }else {
            if(this.isUserPending)
              router.push({ name: 'UserPending'})
              // router.push({ name: 'Home'})
            else
              router.push({ name: 'Home'})
            
          }
          
        })
        .catch(function (error) {
          vm.logging = false;
          vm.test = error;
          switch (error.response.status) {
            case 401: {
              let eventObj = {...error.response.data};
              eventObj.color = "error";
              EventBus.$emit('hasError', eventObj);
              break;
            }
          }
        })
        .finally(()=> {
          this.logging=false
          });
      } 
    },

    fetchJob () {
      return http.get("candidates/fetch-job")
      .then(res => {
          this.jobs = res.data.items;
      }).catch(err => {throw err});
    },
    fetchCity () {
      return http.get("cities", {
          params: {dropDown: 1}
      }).then(res => {
          this.cities = res.data.items;
      }).catch(err => {
          console.log(err);
      });
    },
    fetchDistrict (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.districts = res.data;
        });
    },
    fetchWard (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.wards = res.data;
        });
    },

    fetchDistrict2 (val) {
      if(val)
        return http.get('cities/fetch-district/'+val).then(res => {
            this.district2s = res.data;
        });
    },
    fetchWard2 (val) {
      if(val)
        return http.get('districts/fetch-ward/'+val).then(res => {
            this.ward2s = res.data;
        });
    },
    fetchEducationLevel(){
      ApiService.fetchEducationLevel().then(res=>{
        this.educationLevels = res.data;
      })
    },
    fetchEducationMajor(){
      ApiService.fetchEducationMajor().then(res=>{
        this.educationMajors = res.data;
      })
    },
    fetchUniversity(){
      ApiService.fetchUniversity().then(res=>{
        this.universities = res.data;
      })
    },
    fetchEthnicity(){
      return ApiService.fetchEthnicity().then(r => {
        this.ethnicities =  r.data
      })
    },
    fetchOrigin(){
      return ApiService.fetchOrigin().then(r => {
        this.origins =  r.data
      })
    },
    fetchReligion(){
      return ApiService.fetchReligion().then(r => {
        this.religions =  r.data
      })
    },

    onScroll() {
      let v = window.pageYOffset || document.documentElement.scrollTop;
      if (v > 0) {
        if(this.isScrolling){
          this.isMouseVisible = false
        }
          this.isScrolling = false
      } else {
        this.isMouseVisible = true
        if(!this.isScrolling){
          this.isScrolling = true
        }
      }
        // this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // if(document.documentElement.scrollTop < 11)
      //   this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      // else {
      //   console.log(this.scrollTop)
      // }
      
      // Do something with the scroll position, e.g., update a data property
      // this.scrollPosition = scrollTop;
      // console.log('Main page scroll position:', this.scrollTop);
    },
    applyJob() {
      var formData = new FormData();
      if(!!this.attachmentFile){
        this.form.attachmentFile = this.attachmentFile.forEach(file => {
          formData.append("attachmentFile", file);
        });
      }
      if(this.form.birthday ==null)
      {
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Hãy nhập ngày sinh của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      if(this.avatarFile)
        formData.append("avatar", this.avatarFile);
      else
      {
        this.avatarFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chọn ảnh avatar của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }

      if(this.idImageFrontFile)
        formData.append("idImageFront", this.idImageFrontFile);
      else
      {
        this.idImageFrontFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chụp CMND/CCCD mặt trước của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      
      if(this.idImageBackFile)
        formData.append("idImageBack", this.idImageBackFile);
      else
      {
        this.idImageBackFile = null;
        this.valid = false;
        
        Swal.fire(
          {
            title: 'Thông báo',
            html: 'Chụp CMND/CCCD mặt sau của bạn',
            icon:'error',
            showCloseButton: false
          }
        );
        return;
      }
      
      if(this.cvFile)
        formData.append("cvFile", this.cvFile);
      else
      {
        this.cvFile = null;
        // this.valid = false;
        
        // Swal.fire(
        //   {
        //     title: 'Thông báo',
        //     html: 'Chọn file CV của bạn',
        //     icon:'error',
        //     showCloseButton: false
        //   }
        // );
        return;
      }

      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }

      // this.$refs.candidate.validate();
      this.valid =true
      if(this.valid){
        this.isLoading=true;
        return http.post(this.CANDIDATE_URL, formData).then(res=>{
          // this.$refs.candidate.reset();
          this.form = Object.assign({}, this.defaultForm);
          this.isLoading=false;
          this.form.height = 0;
          this.form.weight = 0;

          Swal.fire(
            {
              title: 'Thông báo',
              html: '<p>Cảm ơn bạn đ&atilde; ứng tuyển v&agrave;o Hệ Thống Nh&agrave; Thuốc Trung Sơn.</p> <p>Theo nhu cầu tuyển dụng nhận sự của c&ocirc;ng ty. Ch&uacute;ng t&ocirc;i sẽ chủ động li&ecirc;n hệ với c&aacute;c hồ sơ ph&ugrave; hợp để th&ocirc;ng b&aacute;o lịch phỏng vấn.</p> <p>Th&acirc;n mến!!</p><p>&nbsp;</p><p><em>Th&ocirc;ng tin li&ecirc;n hệ SDT: <a class="text-is-phone-number">088 8786787</a><a class="text-is-phone-number">&nbsp;</a><span class="text">/ </span><a class="text-is-phone-number">0867742768</a></em></p>',
              icon:'success',
              closeButtonText: 'Đồng ý',
              showCloseButton: true
            }).then(result => {
              window.location.reload();
            })
        }).catch(err=>{console.log(err)})
        .finally(()=>{
          this.isLoading=false;
        })
      } else {
          let eventObj = {
              color: "error",
              message: "Kiểm tra lại dữ liệu",
              toast: false,
          };
          EventBus.$emit('hasError', eventObj);
      }
    },
  },
  
  updated()
  {
    
  },
  
  mounted() {
    this.fetchJob();
    this.fetchCity();
    this.fetchEducationLevel();
    this.fetchEducationMajor();
    this.fetchUniversity();
    this.fetchEthnicity();
    this.fetchOrigin();
    this.fetchReligion();

    //load remember
    let rememberUser = localStorage.getItem('rememberUser')
    let rememberPassword = localStorage.getItem('rememberPassword')
    if(rememberUser && rememberPassword){
      this.username = rememberUser
      this.password = rememberPassword
      this.rememberLogin = true
      this.verifyUser()
    }
    setTimeout(() => {
      this.show = true;
    }, 500);

  },
  apiService: null,
  created() {
    this.apiService = new Api()
    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* .slide-fade-enter-active {
  transition: all .9s ease-in-out;
} */
/* .slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
} */
/* .slide-fade-leave-active below version 2.1.8 */
/* .slide-fade-enter, .slide-fade-leave-to
 {
  transform: translateX(-10px);
  opacity: 0;
} */


::v-deep .swal2-content {
  text-align: left !important;
}
.container.custom{
  max-width: 800px;
}

/* .v-text-field--outlined >>> fieldset {
  border-color: rgba(192, 0, 250, 0.986);
} */

.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .7;
    position: absolute;
    width: 100%;
}

.image-container {
  position: relative;
  width: 300px; /* Adjust the size according to your needs */
  height: 200px; /* Adjust the size according to your needs */
}

.loading-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0; /* Adjust the color as you like */
  animation: pulse 1.5s infinite ease-in-out; /* Adjust the animation properties as you like */
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.opacity-mask {
  background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(255, 255, 255, 0));
}
::v-deep div.v-stepper__label {
  text-align: center;
  line-height: normal;
  max-width: 100px;
}
</style>